import auth from "@/logic/auth.ts";
import LoadingBar from "@/components/LoadingBar.vue";
import comercio from "@/logic/comercio.ts";
import swallHelper from "@/logic/helpers/swall";
import * as status from "@/logic/helpers/funtions";
export default {
  components: {
    LoadingBar,
  },
  data() {
    return {
      comercio: [
        {
          objeto: "",
          fecha_inicio: "",
          adrema: "",
          inmueble: "",
          sup_comercio: "",
          nombre_fantasia: "",
          titular: "",
          razsoc: "",
          dom_fisico_titular: "",
          dom_comercial_real: "",
          dom_comercial_postal: "",
          cod_act: "",
          descripcion: "",
          expediente: "",
          num_resol: "",
          disposicion: "",
          clase_obj: "",
          clase: " ",
          cod_baja: "",
          fecha_baja: "",
        },
      ],
      numero: "",
      submitted: false,
      errors: [],
      err: false,
      isActive: true,
      isVisible: false,
    };
  },
  methods: {
    resetFields(boolean) {
      this.isActive = true;
      this.isVisible = false;
      this.submitted = boolean;
      this.numero = "";
    },
    async query() {
      try {
        this.submitted = false;
        this.errors = [];
        this.items = {};
        if (!this.numero) {
          this.items = {};
          this.submitted = false;
          return this.errors.push("Debe de ingresar un valor");
        }
        const queryParam = {'adrema': this.numero}
        if (location) {
          this.isActive = false;
          this.isVisible = true;
          const comercioData = await comercio.getData(
            queryParam,
            location.latitud,
            location.longitud
          );
          this.resetFields(true);
          this.items = comercioData;
        } else {
          this.isActive = false;
          this.isVisible = true;
          const comercioData = await comercio.getData(queryParam);
          this.resetFields(true);
          this.items = comercioData;
        }
      } catch (err) {
        await status.refresh(err.response.status);
        this.resetFields(false);
        if (err.response?.status == 504) {
          return this.errors.push(
            "Sevicio no disponible momentáneamente. Intente nuevamente más tarde"
          );
        }
        return this.errors.push(
          "No se especificaron correctamente los parametros."
        );
      }
    },
    async advancedSearch(search) {
      try {
        const result = await swallHelper.swallPassword();
        if (Object.keys(result.value).length >= 1) {
          this.submitted = false;
          this.errors = [];
          this.items = {};
          const location = await auth.getLocation();
          if (location) {
            this.isActive = false;
            this.isVisible = true;
            const comercioData = await comercio.getData(
              result.value,
              location.latitud,
              location.longitud
            );
            this.resetFields(true);
            this.items = comercioData;
          } else {
            this.isActive = false;
            this.isVisible = true;
            const comercioData = await comercio.getData(result.value);
            this.resetFields(true);
            this.items = comercioData;
          }
        }
        return;
      } catch (err) {
        await status.refresh(err.response.status);
        this.resetFields(false);
        if (err.response?.status == 504) {
          return this.errors.push(
            "Sevicio no disponible momentáneamente. Intente nuevamente más tarde"
          );
        }
        return this.errors.push(
          "No se especificaron correctamente los parametros."
        );
      }
    },
  },
};
